import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'usehooks-ts';
import BridgeMessagingAPI from './bridgeMessagingAPI';
import { parseEventMessage, getCookie, isValidOrigin } from './utils';
import { bridgeReducer, initialState } from './store';
import { EventName } from './types';
import { BRIDGE_COOKIE_SESSION_ID_KEY, BRIDGE_STORAGE_KEY } from './config';

export const BridgeContext = createContext(initialState);

const initialAPI = {
  closeWebview: BridgeMessagingAPI.closeWebview,
  openUrl: BridgeMessagingAPI.openUrl,
  openPayment: BridgeMessagingAPI.openPayment
};

export const BridgeAPIContext = createContext(initialAPI);

const BROWSER_LIST_ORIGINS = [
  'https://www.instagram.com',
  'https://www.facebook.com',
  'https://maps.google.com',
  'https://www.youtube.com',
  'https://youtu.be'
];

export const BridgeProvider = ({ children, isWebView }) => {
  const [state, dispatch] = useReducer(bridgeReducer, {
    ...initialState,
    isWebView
  });
  const [storage, setStorage] = useLocalStorage(BRIDGE_STORAGE_KEY, null);

  const sessionId = useRef(Number(getCookie(BRIDGE_COOKIE_SESSION_ID_KEY)));

  useEffect(() => {
    const currentSessionId = sessionId.current;

    let payload = { ...initialState, isWebView };

    if (storage && currentSessionId) {
      if (storage.sessionId === currentSessionId) {
        payload = storage;
      } else {
        setStorage(null);
      }
    }

    dispatch({ type: EventName.SyncStorage, payload });
  }, []);

  useEffect(() => {
    if (storage) return;

    if (state.isSetConfig && state.isConnected) {
      BridgeMessagingAPI.handshake();
    } else if (state.isConnected) {
      const config = {
        originBrowserList: BROWSER_LIST_ORIGINS,
        noTopInsetRoutes: []
      };

      BridgeMessagingAPI.setConfig(config);
    } else {
      BridgeMessagingAPI.connecting();
    }
  }, [storage, state]);

  const onHandshake = useCallback(() => {
    if (sessionId.current) {
      state.sessionId = sessionId.current;
    }

    state.isWebView = true;

    setStorage(state);
  }, [state]);

  useEffect(() => {
    const callback = nativeEvent => {
      const message = nativeEvent.data;

      try {
        const data = parseEventMessage(message);

        if (!data?.params?.origin || !isValidOrigin(data.params.origin)) return;

        const { eventName, params } = data;

        if (!eventName) return;

        if (eventName === EventName.Handshake) onHandshake();

        dispatch({
          type: eventName,
          payload: params
        });
      } catch (error) {
        console.log(error);
      }
    };

    return BridgeMessagingAPI.subscribe(callback);
  }, [onHandshake]);

  return (
    <BridgeContext.Provider value={state}>
      <BridgeAPIContext.Provider value={initialAPI}>
        {children}
      </BridgeAPIContext.Provider>
    </BridgeContext.Provider>
  );
};

BridgeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isWebView: PropTypes.bool
};
